// AppMsg for socket-based communication with server
// CdC 11/9/20

let socketURL;
socketURL = 'wss://appfulwss.ngrok.io';
//socketURL = 'ws://localhost:5000';

let msg;
msg = {"type":"command","command":"play","soundname":"soundname"};
msg = {"data":[1,2,4,7]};
AppMsg = {
    logit: false,
    appSocket: null, // socket for server communication of data and commands, managed by AppMsg
    initialized: false,
    socketOpen:false,
    skip: false, // skip socket communication
    error:false,

    start() {
        if (this.skip) return;
        AppMsg.appSocket = new WebSocket(socketURL);
        AppMsg.appSocket.onopen = (e) => {
            AppMsg.socketOpen = true;
        }
        AppMsg.appSocket.onerror = (e) => {
            AppMsg.error = true;
            console.log('AppMsg Socket Error: ', e);
        }
        AppMsg.appSocket.onmessage = (e) => {
            if (!AppMsg.socketOpen) return;
            msg = e.data;
            if (this.logit) console.log(msg);
            //$('#messages2').append($('<li>').text(msg));
            //window.scrollTo(0, document.body.scrollHeight);
            if (this.logit) console.log('Msg:',msg)
            if (IsJsonString(msg)) {
                let msgObj = JSON.parse(msg);
                if ('command' in msgObj) {
                    if (this.logit) console.log('Command: ',msgObj);
                    this.processCmd(msgObj);
                }
            }
            else {
            }
        }

    },
    processCmd(msgObj)
    {
        if (AppMsg.logit) console.log('message:',msgObj)
        let AppCmd = msgObj.command;
        if (this.logit) console.log(AppCmd);
            switch (AppCmd) {
            case 'AppBell':         AppAudio.bell(); break;
            case 'sessionStart':    AppAudio.sessionStart(); break;
            case 'play':            AppAudio.makeNoise(); break;
            case 'pauseAll':        AppAudio.pauseAll(); break;
            case 'resumeAll':       AppAudio.resumeAll(); break;
            case 'silence':         AppAudio.silence(); break;
            case 'unSilence':       AppAudio.unSilence(); break;
            case 'buzz':            AppAudio.buzz(); break;
            case 'backgroundStart': AppAudio.backgroundSoundStart(); break;
            case 'breathingStart':  AppAudio.breathingSoundStart(); break;
            case 'targetStart':     AppAudio.targetStart(); break;
            case 'targetStop':      AppAudio.targetStop(); break;
            //case 'repeater':        AppAudio.repeaterStart(msgObj.ISI); break; // What is this for?
            case 'heartrate':       Physio.heartrate = msgObj.heartrate; break;//Tone.Transport.bpm.value = 58*6/8;break;
            case 'nextbeats':       Physio.nextbeats = msgObj.nextbeats; break;
            case 'breathScores':
                Physio.breathScores = msgObj.breathScores;
                AppAudio.processBreathScores(Physio.breathScores);
                break;
            case 'breathingrate':   Physio.breathingrate = msgObj.breathingrate; break;
            case 'nextbreaths':     Physio.nextbreaths = msgObj.nextbreaths; break;
            case 'breathsPerMinuteSet':Physio.breathsPerMinuteSet(msgObj.value); break;
            case 'beatsPerMinuteSet':    Physio.beatsPerMinuteSet(msgObj.value); break;
            case 'AppReload':       location.reload(); break;
            case 'userUpdate':      AppAudio.userUpdate(); break;
        }
    },
    send(msg) {
        if (!AppMsg.socketOpen) return;
        msg.sendTime = Math.round(AppAudio.now()*1000)/1000; // time in sec
        if (this.logit) console.log('Sending: ',msg);
        let msgString = JSON.stringify(msg);
        AppMsg.appSocket.send(msgString);
    },
    socketClosed() {
        AppMsg.socketOpen = false;
    },
    sendData(data) {
        msg = {};
        msg.command = 'data';
        msg.data = data;
        //if (AppMsg.logit) console.table(data);
        AppMsg.send(msg);
        //if (AppMsg.logit) console.log(msg);
    },
    test(){
        let msg = {'number':Math.random()};
        AppMsg.send(msg);
    }
}
AppMsg.start();

//https://stackoverflow.com/questions/18787710/javascript-send-array-of-ints-through-node-js-binary-websockets
// Convert a "binary" encoded string into a binary buffer.
function binaryEncodeData2d(data) {
    let dataBinary = new Int16Array(0);
    for (let ind1 = 0, len1 = data.length; ind1 < len1; ind1++) {
        for (let ind2 = 0, len2 = data[0].length; ind2 < len2; ind2++) {
            dataBinary[ind1*len1 + ind2] = data[ind1][ind2];
        }
    }
    return dataBinary
}

// Convert a "binary" encoded string into a binary buffer.
function binaryDecode(string) {
    let length = string.length;
    let buffer = new Buffer(length);
    for (let i = 0; i < length; i++) {
        buffer[i] = string.charCodeAt(i);
    }
}
function IsJsonString(str) {
    try {
        let json = JSON.parse(str);
        return (typeof json === 'object');
    } catch (e) {
        return false;
    }
}


